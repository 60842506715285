import React from "react"
import { Helmet } from "react-helmet"
import ProductsNew from "../components/goods/ProductsNew"

const VyhledavaniPage = ({ location }) => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Vyhledávání | FÖDA</title>
      </Helmet>
      <ProductsNew location={location} />
    </>
  )
}

export default VyhledavaniPage
